<template>
    <auth-page-template heading="Create an account" :back-link="true">
        <template #default>
            <div class="mt-4">
                <validated-input label="Email">
                </validated-input>
            </div>
            <div>
                <validated-input type="password" label="Enter new password">
                </validated-input>
            </div>
            <div>
                <validated-input type="password" label="Confirm password">
                </validated-input>
            </div>
            <div class="px-4">
                <btn design="" block class="btn-bg-white mt-4" size="lg" border-radius="4" text="Submit"></btn>
            </div>
        </template>
    </auth-page-template>
</template>

<script>
export default {
    name: 'CreateAnAccountSubmit'
};
</script>

<style scoped>

</style>
